@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/close";

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    //margin: 1.75rem auto;
    margin: 0.5rem auto;
  }
}

@media (min-width: 640px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

.m-dialog--medium {
  max-width: 700px;
}

.m-dialog--large {
  max-width: 945px;
}

.m-dialog--xlarge {
  max-width: 1140px;
}

.m-dialog--xlarge2 {
  max-width: 1280px;
}

.modal-content {
  border: none;
}

button.close {
  top: 32px;
  right: 49px;
  font-size: 48px;
  padding: 16px 16px;
  margin: -16px -16px -16px auto;

  span {
    display: block;
    line-height: 32px;
  }
}

.modal-backdrop+.modal-backdrop {
  z-index: 1060;
}

#dialog2 .modal {
  z-index: 1070;
}

.modal-backdrop+.modal-backdrop+.modal-backdrop {
  z-index: 1080;
}

#dialog3 .modal {
  z-index: 1090;
}
