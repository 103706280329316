.m-overlay {
  position: relative;
}

.m-overlay__label {
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 10px);
  font-size: 32px;
  color: rgba(0,0,0,.64);
  display: none;
}

.m-overlay__content {
  opacity: 1
}

.m-overlay--visible {
  @extend .m-overlay;
  pointer-events: none;

  .m-overlay__label {
    display: block;
  }

  .m-overlay__content {
    opacity: 0.4;
  }
}
