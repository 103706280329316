$loading-screen-background: #F59F3A;
$loading-screen-gradient-color-1: #FAB630;
$loading-screen-gradient-color-2: #F47D21;
$loading-screen-logo-size: 175px;
$loading-screen-logo-height: $loading-screen-logo-size;
$loading-screen-logo-width: $loading-screen-logo-size;

@-webkit-keyframes logo-breathe {
  from {
    opacity: .2;
    filter: alpha(opacity=20)
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100)
  }
}

@keyframes logo-breathe {
  from {
    opacity: .2;
    filter: alpha(opacity=20)
  }
  to {
    opacity: 1;
    filter: alpha(opacity=100)
  }
}

#loading-screen {
  background: $loading-screen-background;
  //background-image: linear-gradient($loading-screen-gradient-color-1, $loading-screen-gradient-color-2);
  background-image: radial-gradient($loading-screen-gradient-color-1, $loading-screen-gradient-color-2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
  opacity: 1;
  transition: opacity 0.75s ease;

  &.loading-screen-fade {
    opacity: 0;
  }

  &.loading-screen-hide {
    display: none;
  }

  .logo {
    position: absolute;
    background-image: url(../images/logo.png);
    height: $loading-screen-logo-height;
    width: $loading-screen-logo-width;
    background-size: $loading-screen-logo-width $loading-screen-logo-height;
    top: calc(50% - #{$loading-screen-logo-height/2});
    left: calc(50% - #{$loading-screen-logo-width/2});
  }

  .logo.breathe {
    animation-name: logo-breathe;
    animation-duration: 1.8s;
    animation-delay: .2s;
    animation-timing-function: cubic-bezier(.73, .005, .42, 1.005);
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}
