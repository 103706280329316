@tailwind base;
@tailwind components;

html {
  //font-size: 16px;
}

body {
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  //color: #4a4a4a;
  //@apply text-red-500;
  @apply text-cs-gray-700;
  @apply text-base;
}

.cs-sidenav {
  width: 256px;
  right: -256px;
}

.container {
  max-width: 1440px;
  margin: auto;
}

.page {
  @apply px-8;
  @apply py-8;
}

p:not(:last-child) {
  @apply mb-4;
}

.nth-3n\:pr-0:nth-child(3n) {
  @apply pr-0;
}

.dialog-previous-button {
  top: 34px;
  left: 32px;
}

.cs-map {
  height: 192px !important;
  width: 100% !important;
  max-height: 192px  !important;
}

@media (min-width: 640px) {
  .sm\:nth-3n\:pr-0:nth-child(3n) {
    @apply pr-0;
  }

  .sm\:dialog-previous-button-sm {
    top: 41px;
    left: 59px;
  }

  .cs-map {
    height: 100% !important;
  }
}

@media (min-width: 768px) {
  .md\:nth-3n\:pr-0:nth-child(3n) {
    @apply pr-0;
  }

  .md\:dialog-previous-button-md {
    top: 41px;
    left: 59px;
  }
}

@media (min-width: 1024px) {
  .lg\:nth-3n\:pr-0:nth-child(3n) {
    @apply pr-0;
  }

  .lg\:dialog-previous-button-lg {
    top: 41px;
    left: 59px;
  }
}

@media (min-width: 1280px) {
  .xl\:nth-3n\:pr-0:nth-child(3n) {
    @apply pr-0;
  }
}

a {
  @apply text-cs-orange-500;
}

.pac-container:after{
  //display: none !important;
  @apply mr-6 mt-2;
}

.pac-container {
  //font-family: Roboto, Helvetica, Arial, sans-serif;
  z-index: 2000;

  @apply shadow-welcome rounded-b-xl pb-2 block;

  .pac-item {
    @apply py-2 px-6 cursor-pointer;

    .pac-icon {
      @apply hidden;
    }

    .pac-item-query {
      //@apply block;
      @apply block text-sm leading-normal pt-1;
    }

    span:nth-child(3) {
      @apply block text-xs leading-normal font-medium pb-1;
    }
  }
}

.__react_component_tooltip.tooltip-custom-theme.show {
  @apply bg-white;
  @apply shadow-welcome;
  @apply px-3;
  @apply py-2;
  @apply opacity-100;
  @apply pointer-events-auto;
  //@apply rounded-cs-10;
  @apply rounded-cs-8;
  @apply z-50;
  @apply border border-gray-200;
}

@import "forms";
@import "modal";
@import "popovers";
@import "overlay";
@import "loader";

@tailwind utilities;
