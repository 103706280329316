@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/input-group";

/**
 * Forms
 */

.form-group {
  @apply mb-8;
}

.form-group label {
  @apply block mb-3;
  //font-size: 15px;
  //margin-bottom: 11px;
  //color: #4a4a4a;
}

.form-control {
  @apply px-4 py-3;
  //@apply block w-full leading-loose bg-white border border-gray-500 rounded px-4 py-3;
  //transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group small {
  @apply block text-sm mt-2 text-gray-600;
}

input.form-control,
select.form-control {
  height: 56px;
}

input.form-control,
textarea.form-control,
select.form-control {
  //font-size: 17px;
  //font-style: normal;
  padding: 15px 20px;

  //min-height: 56px;
  //appearance: none;

  &::placeholder {
    //font-style: italic;
    //font-weight: 300;
    //color: #545454;
    //font-style: italic;
    font-weight: 400;
    color: #B0BEC5;
  }
}

.form-group:last-of-type {
  margin-bottom: 0;
}
