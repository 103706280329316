@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/dropdown";

.tooltip {
  &.show {
    @apply opacity-100;
  }

  .arrow::before {
    border-top-color: #fff;
    //@apply shadow;
  }

  .tooltip-inner {
    @apply bg-white text-gray-600 shadow-welcome border-0 rounded px-4 py-4;
  }
}


